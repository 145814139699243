import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm'
import { TOKEN_KEY, isValidToken } from '../utils';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"
import "./ProjectIdPage.css"
import CenterColumn from '../components/CenterColumn';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function ProjectIdPage() {
  let { id } = useParams();

  const [projectData, setProjectData] = useState(null);

  const user_token = localStorage.getItem(TOKEN_KEY);

  useEffect(() => {
    async function getData() {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}?populate[thumbnail][populate]=true&populate[services][populate]=true&populate[outputs][populate][0]=file&populate[outputs][populate][1]=images&populate[internal][populate]=true`, {
        headers: isValidToken(user_token) ? {
          'Authorization': `Bearer ${user_token}`
        } : undefined
      });
      const json = await res.json();
      // json.data.attributes.outputs.sort(sortOutput);
      setProjectData(json.data.attributes);

      document.title = `${json.data.attributes.name} – Creative Dreamers – reference`;
    }
    getData();
  }, []);

  function sortOutput(a, b) {
    if (a.type === "gallery") {
      return -1;
    }
    if (b.type === "gallery") {
      return 1;
    }
    return 0;
  }

  return (
    <div>
      <Header showEdit={user_token} />
      {projectData &&
        <CenterColumn>
          <img style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }} src={`${projectData.thumbnail.data?.attributes?.url}`} alt='Project thumbnail' />
          <h1 style={{
            // margin: 0
          }}>{projectData.name}</h1>
          {/* <p style={{ margin: 0, marginBottom: 4 }}><b>{projectData.clientName}</b></p>
          <p style={{ margin: 0 }} className='cd_green'>Služby: {projectData.services.data.map(service => service.attributes.name).join(", ")}</p>
          <p style={{ margin: 0 }} className='cd_green'>Rok realizace: {projectData.year}</p> */}

          <div style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
            flexWrap: "wrap",
            rowGap: "0px",
            columnGap: "20px",
            alignItems: "center",
          }}>
            <div>
              <h2 className='cd_green_normal'>Služby</h2>
              <p>
                {projectData.services.data.map(service => service.attributes.name).join(", ")}
              </p>
            </div>
            <div>
              <h2 className='cd_green_normal'>Rok realizace</h2>
              <p>
                {projectData.year}
              </p>
            </div>
            <div>
              <h2 className='cd_green_normal'>Klient</h2>
              <p>
                {projectData.clientName}
              </p>
            </div>
          </div>

          {/* <Markdown remarkPlugins={[remarkGfm]}>
            {projectData.description}
          </Markdown> */}
          <p style={{ whiteSpace: 'pre-line', margin: "20px 0 30px 0" }}>{projectData.description}</p>

          <h2 style={{ margin: "20px 0" }}>Výstupy</h2>
          <div>
            {projectData.outputs.map((output, index) => {
              switch (output.type) {
                case "iframe":
                  const iframeString = output.content.replace(/width="[^"]*"/, 'width="100%"').replace(/height="[^"]*"/, 'height="100%"');
                  return <div key={index} dangerouslySetInnerHTML={{ __html: iframeString }}></div>
                case "youtube":
                  return <iframe width={"100%"} style={{ aspectRatio: 16 / 9 }} key={index} title={`Youtube video: ${output.content}`} src={output.content} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                case "vimeo":
                  return <iframe width={"100%"} style={{ aspectRatio: 16 / 9 }} key={index} title={`Vimeo video: ${output.content}`} src={output.content} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                case "link":
                  return <div style={{ marginBottom: "5px" }}>
                    <svg style={{ verticalAlign: "bottom" }} width="24px" height="24px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(-45)"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#fff" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16" stroke="#000000" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <span>&ensp;&nbsp;</span>
                    <a className='link' key={index} href={output.content} target="blank">{output.content.replace(/(^\w+:|^)\/\//, '')}</a>
                    <br />
                  </div>
                case "pdf":
                  if (output.file.data === null || output.file.data === undefined) return <></>;
                  return <div style={{ marginBottom: "5px" }}>
                    <svg style={{ verticalAlign: "bottom" }} width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 17H15M9 13H15M9 9H10M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <span>&ensp;&nbsp;</span>
                    <a className='file-link' key={index} href={`${output.file.data.attributes.url}`} target="blank">{`${output.file.data.attributes.name} (${output.file.data.attributes.size}kB)`}</a>
                    <br />
                  </div>
                case "gallery":
                  if (output.images.data === null || output.images.data === undefined) return <></>;
                  return <ImageGallery lazyLoad={true} key={index} additionalClass='gallery-root' showPlayButton={false} showThumbnails={true} showBullets={output.images.data.length > 1 ? true : false} items={output.images.data.map(image => {
                    return {
                      original: `${image.attributes.url}`,
                      thumbnail: `${image.attributes.formats?.thumbnail?.url ?? image.attributes.url}`,
                      originalClass: "gallery-img"
                    }
                  })} />;
                default:
                  return null;
              }
            })}
          </div>
          {projectData.internal && projectData.internal.data && <>
            <h2 style={{ margin: "35px 0 15px 0" }}>Interní informace</h2>
            <p className='cd_green'>Account: {projectData.internal?.data.attributes.account}</p>
            <p className='cd_green'>Projekťák: {projectData.internal?.data.attributes.projectManager}</p>
            <p className='cd_green'>Rozpočet: {projectData.internal?.data.attributes.budget}</p>
            <p className='cd_green' style={{
              display: "flex",
            }}>Hodnocení projektu:&ensp;
              {Array(projectData.internal?.data.attributes.rating).fill().map((_, i) => (
                <img key={i} src={`${process.env.PUBLIC_URL}/hvezdicka.svg`} alt='star' style={{ width: "20px" }} />
              ))}
              {Array(5 - projectData.internal?.data.attributes.rating).fill().map((_, i) => (
                <img key={i} src={`${process.env.PUBLIC_URL}/hvezdicka-prazdna.svg`} alt='star' style={{ width: "20px" }} />
              ))}
            </p>
            {/* <Markdown remarkPlugins={[remarkGfm]}>
              {projectData.internal?.data.attributes.internalDescription}
            </Markdown> */}
            <p style={{ whiteSpace: 'pre-line' }}>{projectData.internal?.data.attributes.internalDescription}</p>
          </>}
        </CenterColumn>
      }
      <Footer />
    </div>
  );
};
