import "./Footer.css";

export default function Footer() {
    return <div className="footer">
        <div className="footer-bar">
            <div style={{
                minWidth: "65%",
                maxWidth: "1300px",
                padding: "0 20px",
                flex: 1,
                display: "flex",
            }}>
                <div className="footer-bar-items">
                    <b>Jdeme na to?</b>
                    <a href="mailto:info@creativedreamers.cz">Napište nám</a>
                </div>
            </div>
        </div>
        <div className="footer-root">
            <img className="logo" src="/CreativeDreamers_white.svg"></img>
            <div className="text" >
                <div>
                    <div>
                        <p>
                            Jsme mladá agentura punkáčů v košilích, kteří rozumí potřebám klientů. Naší specialitou jsou příběhové eventy, natáčení, focení a využívání prvotřídních nejmodernějších technologií. V minulosti jsme předali vysněné myšlenky klientů jako jsou např. TotalEnergies, Unilever nebo ČVUT. Naše nápady a extenzivní realizace jste mohli vidět např. na gala večeři s prezidentem Petrem Pavlem, písčitém Dakaru nebo celosvětově známém KVIFFu.
                        </p>
                        <p>
                            Potkejte se s naším usměvavým týmem, jdeme vám naproti!
                        </p>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <a href="tel:+420732258247">
                                            <img loading="lazy" decoding="async" src="/icon_phone.svg" width="40" height="40" alt="" />
                                        </a>
                                    </td>
                                    <td style={{ paddingLeft: 10 }}>
                                        <a href="tel:+420732258247">+420 732 258 247</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="mailto:info@creativedreamers.cz">
                                            <img loading="lazy" decoding="async" src="/icon_mail.svg" width="40" height="40" alt="" />
                                        </a>
                                    </td>
                                    <td style={{ paddingLeft: 10 }}>
                                        &nbsp;
                                        <a href="mailto:info@creativedreamers.cz">info@creativedreamers.cz</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <p><a href="https://www.creativeteambuilding.cz/" target="_blank" rel="noopener">Creative&nbsp;Teambuilding</a></p>
                    <p><a href="https://www.creativestudios.cz/" target="_blank" rel="noopener">Creative&nbsp;Studios</a></p>
                </div>
            </div>
            <div className="socials">
                <div>
                    <a href="https://www.linkedin.com/company/creative-dreamers-production/?originalSubdomain=cz" target="_blank">
                        <img loading="lazy" decoding="async" src="/icon_linkedin.svg" width="45" height="45" alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.facebook.com/creativedreamerscz/" target="_blank">
                        <img loading="lazy" decoding="async" src="/icon_facebook.svg" width="45" height="45" alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.instagram.com/CreativeDreamerscz" target="_blank">
                        <img loading="lazy" decoding="async" src="/icon_instagram.svg" width="45" height="45" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </div>
}