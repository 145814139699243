import { Link } from "react-router-dom";
import "./Header.css";

export default function Header({ showEdit = false }) {
    return <div className="header">
        <img width={250} src='/CreativeDreamers_white.svg' style={{
            cursor: { showEdit } ? "pointer" : "default"
        }}
            onClick={() => {
                if (showEdit) {
                    window.location.href = "/projects";
                }
            }}
        />
        {showEdit &&
            <Link to={"edit"} style={{
                display: "flex",
                alignItems: "center",
            }}>
                Upravit
                <img src="/pen.png" width={40} style={{ marginLeft: 5 }} />
            </Link>}
    </div>
}